import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper', 'burger']

  connect() {}

  toggleNav() {
    this.wrapperTarget.classList.toggle('hidden')
    this.burgerTarget.classList.toggle('active')
  }
}
