import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['container', 'nextButton', 'prevButton']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      autoplay: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      },
    })
  }
}
